<template>
  <el-dialog
    :visible.sync="visible"
    :width="width"
    :show-close="false"
    :top="top"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class="el-dialog-border"
    append-to-body
    modal-append-to-body
  >
    <!-- 标题 -->
    <div
      slot="title"
      class="zy-dialog-title"
    >
      <slot name="title">
        <div>{{ title }}</div>
        <div
          v-if="showClose"
          class="zy-dialog-title-close"
          @click="close"
        >
          <i class="el-icon-close"></i>
        </div>
      </slot>
    </div>
    <!-- 内容 -->
    <div
      class="zy-dialog-body"
      v-if="visible"
      :style="{ padding: padding }"
    >
      <slot></slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    // 展示控制
    visible: {
      type: Boolean,
      default: false,
    },
    //宽度
    width: String,
    // 是否展示关闭按钮
    showClose: {
      type: Boolean,
      default: true,
    },
    // 标题
    title: {
      type: String,
    },
    // 内容padding，因为高保真存在不同的padding，可以在这里修改。
    padding: {
      type: String,
      default: "20px",
    },
    //距离顶部距离
    top: String,
  },
  methods: {
    close() {
      this.$emit("update:visible", false)
      this.$emit("close")
    },
  },
}
</script>
<!-- 不能加scoped，否则背景样式不会生效 -->
<style lang="less">
.el-dialog-border {
  border-top-left-radius: 24px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #102046, #102046), linear-gradient(90deg, #33d8fa, #187bcd);
  border: 3px solid transparent;
}
.el-dialog__header,
.el-dialog__body {
  padding: unset !important;
}
.zy-dialog-body {
  min-height: 300px;
  max-height: 640px;
  overflow-y: auto;
}
.zy-dialog {
  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-weight: bold;
    font-size: 18px;
    color: #ffffff;
    background: linear-gradient(343deg, #1c73d3 0%, #00c3f4 100%);
    border-top-left-radius: 18px;
    padding: 15px 20px 15px 18px;
    &-close {
      padding: 0 0 0 12px;
    }
  }
  &-body {
    font-size: 14px;
    color: #ffffff;
  }
}
</style>
